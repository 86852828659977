main {
	min-height: 90vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	color: var(--primary-color);
}
main p {
	width: 60%;
	font-size: 24px;
	text-align: center;
}

main h1 {
	font-size: 3em;
	margin-bottom: 7px;
}

main h3 {
	margin-top: 0;
	font-weight: 400;
	font-size: 30px;
}

.comunidades {
	box-sizing: border-box;
	width: 100%;
	margin: 10px;
	padding: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	justify-items: center;
}

@media only screen and (max-width: 600px) {
	.comunidades {
		display: flex;
		flex-direction: column;
		margin: 10px;
	}
	main {
		width: 100%;
	}
	main h1 {
		font-size: 2.7em;
	}

	main h3 {
		font-size: 26px;
	}
	main p {
		text-align: center;
		width: 90%;
		font-size: 22px;
	}
}
