.card-container {
	box-sizing: border-box;
	width: 27%;
	min-height: 140px;
	padding: 10px;
	margin: 15px;
	border-radius: 2px 2px 10px 10px;
	background-color: var(--secondary-color);
	box-shadow: 0 1px 6px var(--blur-color);
	animation: fadeIn 1.5s ease-in-out;
}

.card-container h3 {
	color: var(--primary-color);
	font-size: 24px;
	font-weight: 500;
	margin-bottom: 10px;
	text-align: center;
}

.card-content {
	height: 90%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.icon-container {
	height: 90%;
	width: 30%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.icon-container .icon {
	height: 70px;
	width: 70px;
}

.text-container {
	width: 70%;
	height: 90%;
	display: flex;
	flex-direction: column;
}

.text-container p {
	font-size: 22px;
	box-sizing: border-box;
	width: auto;
	margin: 5px;
}

.discord {
	border-top: 5px solid #8c9eff;
}

.telegram {
	border-top: 5px solid #139bd0;
}

@media only screen and (max-width: 600px) {
	.card-container {
		width: 80%;
	}
	.card-content {
		flex-direction: column;
	}
	.icon-container {
		height: auto;
		width: 100%;
		flex-direction: column;
	}
	.icon-container .icon {
		height: 60px;
		width: 60px;
	}
	.text-container {
		width: 100%;
		height: auto;
	}
}

@keyframes fadeIn {
	0% {
		transform: scale(0.8);
		opacity: 0;
	}
	100% {
		transform: scale(1);
		opacity: 1;
	}
}
