footer {
	width: 98%;
	border-top: 1px solid var(--blur-color);
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 12vh;
	padding: 20px;
	animation: fadeInUp 1.5s ease-in-out;
	box-sizing: border-box;
}

.links {
	width: 14%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.links img {
	width: 40px;
	height: 40px;
}

.links img:hover {
	transform: scale(1.1);
}

.copy {
	color: var(--primary-color);
}

@media only screen and (max-width: 600px) {
	.links {
		width: 40vw;
	}
	.links img {
		width: 35px;
		height: 35px;
	}
}

@keyframes fadeInUp {
	0% {
		transform: translate(0, 70px);
	}
	100% {
		transform: translate(0, 0);
	}
}
