:root {
	--bg-color: #121212;
	--primary-color: rgba(255, 255, 255, 0.87);
	--secondary-color: rgba(49, 49, 49, 0.13);
	--blur-color: rgba(255, 255, 255, 0.5);
}

body {
	box-sizing: border-box;
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
		"Droid Sans", "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a {
	text-decoration: none;
	color: var(--primary-color);
	font-size: 1.1em;
}
